export const VideoController = (function () {

    class Video {
        constructor(container) {
            this.container = container
            this.videoType = this.container.dataset.type || null
            this.videoID = this.container.dataset.videoId || null
            this.videoPlayer = this.container.querySelector(`.video-canvas__player`)
            this.playButton = this.container.querySelector(`.video-canvas__controls-play`)
            this.player = null
            this.playing = false
            this.playerReady = false
            this.done = false
            this.scrollController= null

            this._init()
        }

        _init() {
            if (this.videoType && this.videoType !== 'native') this.callRespectiveApi()
            this.createScrollHook()
            this._addListeners()
        }

        callRespectiveApi() {
            switch (this.videoType) {
                case 'yt':
                    this.callYtApi()
                    break
            }
        }

        // APIS
        // You tube
        async callYtApi() {
          const target = await this.createPlayer()
          this.playerReady = true
          this.updateHTML()
        }

        createPlayer() {
            return new Promise((resolve, reject) => {
                 this.player = new YT.Player(this.videoPlayer, {
                    height: '100%',
                    width: '100%',
                    videoId: `${this.videoID}`,
                    playerVars: {
                        'playsinline': 1,
                        // 'autoplay': 0,
                        'controls': 0,
                        'autohide': 1,
                        // 'wmode': 'opaque',
                    },
                    events: {
                        'onReady': (e) => {
                            resolve(e.target)
                            this.onPlayerReady(e)
                        },
                        'onStateChange': (e) => this.onPlayerStateChange(e)
                    },
                    
                })
            })
           
        }



        // Generic methods
        onPlayerReady(e) {
            //console.log(e.target.playVideo())
        }

        onPlayerStateChange(e) {
            this.playing  = (this.player.getPlayerState() === 1) ? true : false
            this.updateHTML();
        }

        // callbacks
        _playVideo() {
            this.player.playVideo()
        }
        _pauseVideo() {
            this.player.pauseVideo()
        }
        _stopVideo() {
            this.player.stopVideo()
        }
        

        // Updates
        updateHTML() {
            this.playing ? this.container.classList.add('video-canvas--playing') : this.container.classList.remove('video-canvas--playing')
            this.playerReady ? this.container.classList.add('video-canvas--player-ready') : this.container.classList.remove('video-canvas--player-ready')
        }

        // Scrollhooks
        createScrollHook() {
            if(!this.scrollController) this.scrollController = new ScrollMagic.Controller()
            new ScrollMagic.Scene({ triggerElement: this.container, 
                triggerHook: 'onEnter', 
                offset: 100,
                duration: () => window.innerHeight, 
                reverse: true  
            })
            .on('leave', () => this.playing && this._pauseVideo())
            .addTo(this.scrollController);
        }

        // Addlisteners
        _addListeners() {
            this.playButton.addEventListener("click", (e) => {
                e.preventDefault()
                this.playing ? this._pauseVideo(e) : this._playVideo(e)
            })
        }
    }

    function loadYtScript() {
        const scriptTag = document.getElementById('youtube-api') || document.createElement('script')
        scriptTag.setAttribute('id', 'youtube-api')
        scriptTag.src = "https://www.youtube.com/iframe_api"

        document.body.append(scriptTag)
    }

    return {
        Video,
        loadYtScript
    }

})()

export default VideoController