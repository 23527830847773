import {VideoController} from './Video'

(function(){
    'use-strict'
    const allVideoCanvas = document.querySelectorAll('.video-canvas')
    if(allVideoCanvas.length) {
        VideoController.loadYtScript()
        window.onYouTubeIframeAPIReady = () => allVideoCanvas.forEach((canvas) => new VideoController.Video(canvas))
    }
    
})()