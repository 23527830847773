(function($){

	let defaultOptions = {
		reverse: false,
		hook: "onCenter"
	}

	const MessageInfoAnim= function(ele,options){
		this.settings = $.extend({},defaultOptions, options)
		this.$container = $(ele)
		this.$infoRows = this.$container.find('.info-graphics-row')
		this.$line = this.$container.find('.line-wrapper')
		this.scrollController = new ScrollMagic.Controller()
	}

	MessageInfoAnim.prototype = {
		init() {
			TweenLite.set(this.$container,{autoAlpha: 1})
			for(let i=0; i<=this.$infoRows.length; i++){
				this.setScene(this.$infoRows[i], i)

			}
			this.lineProgressScene()
		},

		setScene(row, index){
			let textContents = $(row).find('.text-wrapper>article>*'),
				waves = $(row).find('.short-wave>*'),
				icon = $(row).find('.wave-icon-wrapper>[class*="icon-"]'),
				tween = new TimelineMax(),
				waveOrigin = "50% 50%",
				waveRotation = 45,
				triggerHook = index === 0 ? "onEnter" : this.settings.hook

				if(index == 0){
					waveOrigin = "0% 100%"
					waveRotation = 45
				} else if(index == 1){
					waveOrigin = "0% 100%"
					waveRotation = 45
				} else if(index == 2) {
					waveOrigin = "0% 100%"
					waveRotation = 45
				}

				tween.staggerFrom(textContents, 0.5, {
					y: 50,
					autoAlpha: 0
				},0.1)
				.staggerFrom(waves,1,{
					rotation: waveRotation,
					autoAlpha: 0,
					ease: Back.easeOut.config(1.8),
					transformOrigin: waveOrigin
				},0.1,0)
				.from(icon,0.5,{
					scale: 0.6,
					autoAlpha: 0
				},0.5)


			let scene = new ScrollMagic.Scene({
				triggerElement: row,
				triggerHook: triggerHook,
				reverse: this.settings.reverse,
				offset: 50,
				duration: 0
			})
			.setTween(tween)
			.addTo(this.scrollController);
		},

		lineProgressScene() {
			let tween = new TimelineMax(),
			line = this.$line.find('svg mask > path'),
			lineOffset = 0

			if(window.innerWidth > 1024){
				lineOffset = 200
			} else {
				lineOffset = 200
			}

			tween.fromTo(line,1,{
				drawSVG:"0% 0%"
			},{
				drawSVG:"0% 100%"
			})

			let scene = new ScrollMagic.Scene({
				triggerElement: this.$container[0],
				triggerHook: this.settings.hook,
				reverse: this.settings.reverse,
				offset: lineOffset,
				duration: "120%"
			})
			.setTween(tween)
			.addTo(this.scrollController);
		}
	}


	$.fn.messageInfoAnimation = function(options) {
		return new MessageInfoAnim(this,options)
	}

	$('.mission-infographics-canvas').messageInfoAnimation({
		reverse: true
	}).init()

})(jQuery)