import  "./partials/missioninfoanimation.js" 
import  "./partials/timeline.js" 
import  "./partials/video" 
(function($) {
  "use strict";

  var S, PassportObj = {
    _init() {
      S = PassportObj.settings
      this.resizeListener()
      //this.htmlScrollEaser() 

      this.circleAtomAnimation()
      if (S.preloader) {
        this.loaderAnimation()
      } else {
        this.detectAnimation()
        TweenLite.to('#main-loader', 0.5, { autoAlpha: 0, display: 'none' })
        $('html').addClass('html-revealed')
        //this.totalPageReveal()
        //this.bodyScroll()
        //this.niceScrolls()
      }
      // Accordian
      this.accordian()
      // Circle loader
      this.circleLoader()
      // redirect loaders
      this.redirectRight()
      this.redirectLeft()
      // nav triggers
      this.navTriggers()
      // tooltips
      this.toolTips()
      // Email popup
      this.emailPopup()
      // Lazyloading images
      this.lazyLoadImg()
      // Fixed sidebars
      this.fixedSideBars()
      // Parallaxes
      this.parallaxOvers()
      this.parallaxScrolls()
      this.manualFormOpener()
      this.scrollDierectionChecker()
      this.scrollBarWidth()
      this.testimonialSlider()
      this.awardSlider()
      this.planSlider()
      this.selectPlanSlider()
      this.articleSlider()
      this.remarkQuoteSider()
      this.articleSliderB()
      this.scrollDisable()
      this.readmoreTrigger()
      this.anchorLinks()

      // Join us btn
      this.joinUsBtnText()
    },

    settings: {
      preloader: true,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      scrollBarWidth: 0,
      scrollClassTrigger: 0
    },

    // Debounce function to optimize event listeners. we dont want it fire every time.
    debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this,
          args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      }
    },

    // Resize Function
    resizeFunctions() {
      S.windowWidth = window.innerWidth
      S.windowHeight = window.innerHeight

      // Join us button
      PassportObj.joinUsBtnText()
    },

    joinUsBtnText() {
      if($('.header-cta-list .join-btn').length) {
        if(S.windowWidth < 575){
          $('.header-cta-list .join-btn>a').html('Join')
        } else {
          $('.header-cta-list .join-btn>a').html('Join for free')
        }
        
      }
    },

    // ResizeListener 
    resizeListener() {
      let resize = this.debounce(this.resizeFunctions, 250)
      window.addEventListener('resize', resize)
    },

    // Scroll Easer: Using jquery plugin
    htmlScrollEaser() {
      $("body").easeScroll({
        frameRate: 60,
        animationTime: 2000,
        stepSize: 120,
        pulseAlgorithm: 1,
        pulseScale: 8,
        pulseNormalize: 1,
        accelerationDelta: 20,
        accelerationMax: 1,
        keyboardSupport: true,
        arrowScroll: 50,
        touchpadSupport: true,
        fixedBackground: false
      });
    },

    // Nice scrol
    bodyScroll() {
      let niceBody = $("body").niceScroll({
        cursorcolor: "#6ec0a6",
        cursorwidth: "12px",
        cursorborder: "0px solid #000",
        scrollspeed: 100,
        autohidemode: true,
        background: '#f6f6f6',
        hidecursordelay: 400,
        cursorfixedheight: false,
        cursorminheight: 20,
        enablekeyboard: true,
        horizrailenabled: false,
        bouncescroll: false,
        smoothscroll: true,
        iframeautoresize: true,
        touchbehavior: false,
        zindex: 999
      });


    },

    niceScrolls() {
      $('.nice-scroll').each((i, elem) => {
        let controller = new ScrollMagic.Controller()
        let Scene = new ScrollMagic.Scene({
          triggerElement: elem
        })
        .on('enter', function(){
          if(elem.niceScrollActivated) return
          let niceScroll = $(elem).niceScroll({
            cursorcolor: "#6ec0a6",
            cursorwidth: "12px",
            cursorborder: "0px solid #000",
            scrollspeed: 100,
            autohidemode: false,
            hidecursordelay: 1000,
            background: '#f6f6f6',
            cursorfixedheight: false,
            cursorminheight: 20,
            enablekeyboard: true,
            horizrailenabled: true,
            bouncescroll: true,
            smoothscroll: true,
            iframeautoresize: true,
            touchbehavior: true,
            zindex: 'auto'
          });
          elem.niceScrollActivated = true
        })
        .addTo(controller)
        
      })
      
    },

    // Loading animation
    loaderAnimation() {
      let loaderMain = $('#loading-svg'),
        loaderIcon = loaderMain.find('.first-path'),
        loaderIconFinal = loaderMain.find('.final-path'),
        loaderTexts = loaderMain.find('.text-layer'),
        tl = new TimelineMax({
          onStart: function() {
            $('body').addClass('no-scroll')
            //.css('padding-right', S.scrollBarWidth)
            //$('#main-header').css('padding-right', S.scrollBarWidth)
          },
          onComplete: function() {
            $('body').removeClass('no-scroll')
            //.css('padding-right', 0)
            //$('#main-header').css('padding-right', 0)
            PassportObj.detectAnimation()
            PassportObj.totalPageReveal()
            setTimeout(function() {
              //PassportObj.bodyScroll()
              PassportObj.niceScrolls()
            }, 2000)

          }
        })



      tl.from(loaderMain, 4, { scale: 0.2, ease: Linear.easeNone })
        .to(loaderMain, 0.5, { autoAlpha: 1 }, 0)
        .to(loaderIcon, 0.5, { morphSVG: { shape: loaderIconFinal } }, 0.5)
        .to(loaderIcon, 0.5, { x: -64 }, 1.9)
        .to(loaderTexts, 0.5, { autoAlpha: 1 }, 2.2)
        .to(loaderMain, 0.5, { autoAlpha: 0 }, 3.5)
        .to('#main-loader', 0.5, { autoAlpha: 0, display: 'none' })

      tl.seek(4)
    },

    // circleLoader
    circleLoader() {
      let loaderSvg = $('.progress-circle-loader.progress-circle-loader.animate-loader')

      loaderSvg.each(function() {
        let circle = $(this).find('circle')

        TweenMax.to(circle, 1.5, { rotation: 360, svgOrigin: "155 155", repeat: -1, ease: Power1.easeInOut })


      })
    },
    scrollDisable() {
      document.onmousewheel = null;
      document.removeEventListener('mousewheel', scroll, false);
      document.removeEventListener('DOMMouseScroll', scroll, false);
    },

    scrollEnable() {
      document.addEventListener('mousewheel', scroll, false);
      document.addEventListener('DOMMouseScroll', scroll, false);
    },
    // redirect loaders
    redirectRight() {
      let redirectSvg = $('.redirect-icon-right.animate')

      redirectSvg.each(function() {
        let arrow = $(this).find('.arrow')
        TweenMax.to(arrow, 1.5, { x: 50, autoAlpha: 0, repeat: -1 })
      })
    },
    redirectLeft() {
      let redirectSvg = $('.redirect-icon-left.animate')

      redirectSvg.each(function() {
        let arrow = $(this).find('.arrow')
        TweenMax.to(arrow, 1.5, { x: -50, autoAlpha: 0, repeat: -1 })
      })
    },

    // Scrollchecker
    // Adding scroll class
    scrollDierectionChecker() {

      function scrollCheck() {
        if (window.scrollY > S.scrollClassTrigger) {
          document.querySelector('body').classList.add('scrolled');
        } else {
          document.querySelector('body').classList.remove('scrolled');
        }
      }

      function scrollDirCheck(e) {
        var delta = ((e.deltaY || -e.wheelDelta || e.detail) >> 10) || 1;;
        if (delta > 0) {

          document.querySelector('body').classList.remove('scrolling-up');
          document.querySelector('body').classList.add('scrolling-down');
        } else {
          document.querySelector('body').classList.remove('scrolling-down');
          document.querySelector('body').classList.add('scrolling-up');
        }
      }

      window.onscroll = function() { scrollCheck() };
      window.addEventListener('mousewheel', scrollDirCheck);
      window.addEventListener('DOMMouseScroll', scrollDirCheck);

    },

    readmoreTrigger() {
      let readmoreTrigger = $('.extend-para'),
      minHeight = 100

      readmoreTrigger.click(function(e){
        e.preventDefault()
        let para = $(this).prev().find('.para-wrapper'),
        panel = $(this).parent(),
        textWrapper = $(this).prev()

        if(!panel.hasClass('active')){
          panel.addClass('active')
          TweenLite.to(textWrapper, 0.5, {maxHeight: para.height(),
            onComplete: function(){
              $('.nice-scroll').getNiceScroll().resize()
            }
          })
          $(this).html('read less')
        } else {
          panel.removeClass('active')
          TweenLite.to(textWrapper, 0.5, {maxHeight: minHeight,
            onComplete: function(){
              $('.nice-scroll').getNiceScroll().resize()
            }
          })
          $(this).html('read more')
        }
      })
    },


    //manualFormOpener
    manualFormOpener() {
      let form = $('.manual-type-form-block'),
        trigger = form.find('.form-selection-trigger')

      trigger.on('click', function(e) {
        e.preventDefault()
        if (!form.hasClass('active')) {
          form.addClass('active')
          trigger.find('.icon-right-open').removeClass('icon-right-open').addClass('icon-down-open')
          let tl = new TimelineLite({
            onComplete: () => {

            }
          })

          tl.to(form.find('.form-elements'), 1, { maxHeight: 1000 })
            .staggerFrom(form.find('.form-elements>*'), 0.5, { y: -50, autoAlpha: 0 }, 0.1, 0)
        }
      })
    },
    //Update scroll bar width
    scrollBarWidth() {
      var outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.width = "100px";
      outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

      document.body.appendChild(outer);

      var widthNoScroll = outer.offsetWidth;
      // force scrollbars
      outer.style.overflow = "scroll";

      // add innerdiv
      var inner = document.createElement("div");
      inner.style.width = "100%";
      outer.appendChild(inner);

      var widthWithScroll = inner.offsetWidth;

      // remove divs
      outer.parentNode.removeChild(outer);

      S.scrollBarWidth = widthNoScroll - widthWithScroll;


    },
    // Accordian
    accordian() {
      let triggerElem = $('.accordian-trigger')
      triggerElem.on('click', function() {
        let panel = $(this).next(),
          parent = $(this).parent()


        panel.slideToggle(200, "swing", () => {
          parent.toggleClass('active')
        })
      })
    },
    // Sliders
    awardSlider() {
      let elem = $('.award-slider')

      elem.on('init', () => addActivePerspective())
      elem.slick({
        arrows: false,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        centerMode: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        responsive: [{
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },{
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: '20%'
          }
        },{
          breakpoint: 568,
          settings: {
            slidesToShow: 1,
            centerPadding: '15%'
          }
        }]
      });

      elem.on('afterChange', function(event, slick, currentSlide, nextSlide) {

        addActivePerspective()
      })
      
      function addActivePerspective() {
        const slides = elem.find('.award-slide')
        const activeSlides = elem.find('.slick-active')

        slides.each((i, slide) => {
          slide.removeAttribute('data-active')
        })
        
        activeSlides.each((i, slide) => {
          slide.setAttribute('data-active', i)
        })
      }
    },

    testimonialSlider() {
      let elem = $('.testimonial-slider')
      elem.slick({
        arrows: false,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }, ]
      });
    },
    planSlider() {
      let elem = $('.plan-slider')
      elem.slick({
        arrows: false,
        infinite: false,
        //initialSlide: 1,
        //centerMode: true,
        //centerPadding: '12%',
        slidesToShow: 3,
        speed: 500,
        responsive: [{
          breakpoint: 742,
          settings: {
            initialSlide: 0,
            centerMode: true,
            centerPadding: '30%',
            slidesToShow: 1
          }
        }, {
          breakpoint: 567,
          settings: {
            initialSlide: 0,
            centerMode: true,
            centerPadding: '20%',
            slidesToShow: 1
          }
        }, {
          breakpoint: 402,
          settings: {
            initialSlide: 0,
            centerMode: true,
            centerPadding: '15%',
            slidesToShow: 1
          }
        },{
          breakpoint: 360,
          settings: {
            initialSlide: 0,
            centerMode: true,
            centerPadding: '13%',
            slidesToShow: 1
          }
        }]
      });
    },
    selectPlanSlider() {
      let elem = $('.select-plan-cards-list')
      elem.slick({
        arrows: false,
        infinite: false,
        //initialSlide: 1,
        //centerMode: true,
        //centerPadding: '12%',
        slidesToShow: 3,
        speed: 500,
        responsive: [{
          breakpoint: 742,
          settings: {
            initialSlide: 0,
            centerMode: true,
            centerPadding: '30%',
            slidesToShow: 1
          }
        }, {
          breakpoint: 567,
          settings: {
            initialSlide: 0,
            centerMode: true,
            centerPadding: '20%',
            slidesToShow: 1
          }
        }, {
          breakpoint: 402,
          settings: {
            initialSlide: 0,
            centerMode: true,
            centerPadding: '13%',
            slidesToShow: 1
          }
        },{
          breakpoint: 360,
          settings: {
            initialSlide: 0,
            centerMode: true,
            centerPadding: '13%',
            slidesToShow: 1
          }
        }]
      });
    },
    remarkQuoteSider() {
      let elem = $('.remark-slider')

      elem.slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 15000,
        dots: true,
        slidesToShow: 1,
        speed: 800,
        //pauseOnHover: false,
      })
    },
    articleSlider() {
      let elem = $('.article-carousel-slider'),
        dir = 'left'
      elem.on('init', function(slick) {
        tweenReveal(elem.find('.slick-current'))
      })
      elem.slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 15000,
        dots: true,
        slidesToShow: 1,
        infinite: true,
        fade: true,
        speed: 800,
        //pauseOnHover: false,
        cssEase: 'linear'
      })

      TweenLite.set(elem.find('article > *,.art-section'), { x: -100, autoAlpha: 0 })
      TweenLite.set(elem.find('.cog'), { transformOrigin: "50% 50%" })
      elem.on('beforeChange', function(event, slick, currentSlide, nextSlide) {

        let nextElem = elem.find('.slick-slide').eq(nextSlide),
          currentElem = elem.find('.slick-slide').eq(currentSlide)

        dir = nextSlide < currentSlide ? 'right' : 'left'
        tweenHide(currentElem, dir)
        tweenReveal(nextElem, dir)
      })

      function tweenReveal(slide, dir) {
        let contents = slide.find('article>*'),
          figure = slide.find('figure'),
          artSection = slide.find('.art-section'),
          plusWrapper = slide.find('.plus-wrapper'),
          cog = slide.find('.cog'),
          paperPlane = slide.find('.paper-plane'),
          staggerItems = slide.find('.stagger-items'),
          tl = new TimelineMax({ delay: 0.8 })


        tl.staggerFromTo(contents, 0.5, {
            x: dir === 'left' ? 100 : -100,
            autoAlpha: 0,
            ease: Power2.easeOut
          }, {
            x: 0,
            autoAlpha: 1,
            ease: Power2.easeOut
          }, 0.1)
          .fromTo(figure, 0.5, {
            x: dir === 'left' ? 100 : -100,
            autoAlpha: 0,
            ease: Power2.easeOut
          }, {
            x: 0,
            autoAlpha: 1,
            ease: Power2.easeOut
          }, 0.5)
          .fromTo(artSection, 0.5, {
            x: dir === 'left' ? 100 : -100,
            autoAlpha: 0,
            ease: Power2.easeOut
          }, {
            x: 0,
            autoAlpha: 1,
            ease: Power2.easeOut
          }, 0.2)

        if (plusWrapper.length) {
          tl.staggerFromTo(plusWrapper.find('>*'), 0.5, {
            y: 50,
            autoAlpha: 0,
          }, {
            y: 0,
            autoAlpha: 1,
          }, 0.1, 0.4)
        }

        if (cog.length) {
          tl.staggerFromTo(cog, 1, {
            rotation: 0
          }, {
            rotation: 180,
            ease: Back.easeOut.config(1.7)
          }, 0.1, 0.2)
        }

        if (paperPlane.length) {
          tl.staggerFromTo(slide.find('.plane-path > g>*'), 0.5, {
              autoAlpha: 0
            }, {
              autoAlpha: 1
            }, -0.05, 0.4)
            .fromTo(paperPlane, 0.5, {
              x: -20,
              y: 20,
              autoAlpha: 0
            }, {
              x: 0,
              y: 0,
              autoAlpha: 1
            }, 1.5)
            .staggerFromTo(slide.find('.plane-traces > *'), 0.5, {
              autoAlpha: 0,
              scale: 0.2
            }, {
              autoAlpha: 1,
              scale: 1
            }, 0.05, 1.5)
        }

        if (staggerItems.length) {
          tl.staggerFromTo(staggerItems.find('> *'), 0.5, {
            y: 20,
            autoAlpha: 0
          }, {
            y: 0,
            autoAlpha: 1
          }, 0.05, 0.2)
        }
      }

      function tweenHide(slide, dir) {
        let contents = slide.find('article>*'),
          figure = slide.find('figure'),
          artSection = slide.find('.art-section'),
          tl = new TimelineMax()

        tl.staggerTo(contents, 0.5, {
            x: dir === 'left' ? -100 : 100,
            autoAlpha: 0,
            overwrite: 'all',
            ease: Power2.easeIn
          }, -0.1)
          .to(figure, 0.5, {
            x: dir === 'left' ? -100 : 100,
            autoAlpha: 0,
            overwrite: 'all',
            ease: Power2.easeIn
          }, 0.2)
          .to(artSection, 0.5, {
            x: dir === 'left' ? -100 : 100,
            autoAlpha: 0,
            overwrite: 'all',
            ease: Power2.easeIn
          }, 0.3)
      }
    },

    // article-slider
    articleSliderB() {
      let wrapper = $('.article-slider-wrapper'),
        elem = $('.article-slider'),
        dir = 'left'
        tweenHide(elem)
        elem.on('init', function(slick) {
          tweenReveal(elem.find('.slick-current'))
          sliderPagination(this)
          scrollBar(0, this)
        })
        elem.slick({
          arrows: false,
          autoplay: true,
          autoplaySpeed: 15000,
          adaptiveHeight: true,
          //dots: true,
          slidesToShow: 1,
          infinite: false,
          fade: false,
          speed: 800,
          //pauseOnHover: false,
          //cssEase: 'linear'
        })

        // arrow navigation

        wrapper.find('.article-prev').click(function() {
          let slider = $(this).parents('.article-slider-wrapper').find('.article-slider')
          slider.slick('slickPrev')
        })
        wrapper.find('.article-next').click(function() {
          let slider = $(this).parents('.article-slider-wrapper').find('.article-slider')
          slider.slick('slickNext')
        })

        // Animations
        elem.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
          let nextElem = elem.find('.slick-slide').eq(nextSlide),
            currentElem = elem.find('.slick-slide').eq(currentSlide)
          dir = nextSlide < currentSlide ? 'right' : 'left'
          tweenHide(currentElem, dir)
          //console.log(nextElem)
          tweenReveal(nextElem, dir)
          scrollBar(nextSlide, this)
        })

        function tweenHide(elem, dir) {
          let tl = new TimelineMax(),
          elemGraphic = $(elem).find('.wave-icon-wrapper'),
          article = $(elem).find('article')

          tl.staggerTo($(elemGraphic).find('.short-wave > path'),1,{
            rotation: 45,
            autoAlpha: 0,
            ease: Back.easeOut.config(1.8),
            transformOrigin: '0% 100%',
            overwrite: "all"
          },0.1)
          .to($(elemGraphic).find('.icon-circle'),0.5,{autoAlpha: 0, scale: 0.5},0)
          .staggerTo($(article).find('>*'),1,{
            y: 100,
            autoAlpha: 0,
            overwrite: "all",
            ease: Power2.easeOut
          },-0.1,0)
        }

        function tweenReveal(elem, dir){
          
          let tl = new TimelineMax({delay: 0.5}),
          elemGraphic = $(elem).find('.wave-icon-wrapper'),
          article = $(elem).find('article')

          tl.staggerTo($(elemGraphic).find('.short-wave > path'),1,{
            rotation: 0,
            autoAlpha: 1,
            ease: Back.easeOut.config(1.8),
            transformOrigin: '0% 100%',
            overwrite: "all"
          },0.1)
          .to($(elemGraphic).find('.icon-circle'),0.5,{autoAlpha: 1, scale: 1},0.2)
          .staggerTo($(article).find('>*'),1,{
            y: 0,
            autoAlpha: 1,
            overwrite: "all",
            ease: Power2.easeOut
          },0.1,0.2)
        }


        // scrollbar nav
        function scrollBar(index, slider) {
          let sliderNav = $(slider).parent().find('.article-slide-nav-wrapper'),
          navItem = sliderNav.find('.slider-nav>li'),
          sliderBar = sliderNav.find('.slider-bar')
          //console.log(sliderNav)
          navItem.removeClass('active')
          navItem.eq(index).addClass('active')

          TweenLite.to(sliderBar, 0.5, {xPercent: index * 100})
        }

        // Slider pagination navigation
        function sliderPagination(slider) {
          let navItem = $(slider).parent().find('.slider-nav>li')
          
          navItem.click(function(){
              $(slider).slick('slickGoTo', $(this).index())
          })
        }

    },
    // Animation standallone
    totalPageReveal() {
      let mainWrapper = $('#main-wrapper main'),
        tl = new TimelineMax
      
      tl.to([mainWrapper, '#main-header', '#main-footer'], 0.5, { scale: 1, autoAlpha: 1, transformOrigin: "50% 0%",
        onComplete() {
          $('html').addClass('html-revealed')

          TweenLite.set([mainWrapper, '#main-header', '#main-footer'], {clearProps:"all"})
        }
      })
    },
    circleAtomAnimation() {
      let atom = $('.atoms-rotate'),
        singlePath = atom.find('.circle-g'),
        centerAtom = atom.find('>circle'),
        tl = new TimelineMax({ delay: 0 })

      singlePath.each(function() {
        tl.to(this, Math.random() * 5 + 3, { rotation: 360, transformOrigin: "center", ease: Power0.easeNone, repeat: -1 }, 0)
          .to(this, Math.random() * 5 + 3, { scale: 0.6, transformOrigin: "center", ease: Power0.easeNone, repeat: -1, yoyo: true }, 0)
      })
      // tl.staggerTo(singlePath, 2, {
      //   rotation: 360,
      //   transformOrigin: "center",
      //   repeat: -1,
      //   repeatDelay: 2,
      //   ease: Back.easeInOut
      // }, 0.05)
    },

    // nav-trigger
    navTriggers() {
      let trigger = $('.nav-trigger');


      trigger.click(function(e) {
        e.preventDefault();
        let target = $(this).data('target');
        // if checks for already active navs
        if (target !== "#main-nav" && $('#main-nav').hasClass('active')) {
          toggleNav("#main-nav")
        }
        if (target !== "#main-side-nav" && $('#main-side-nav').hasClass('active')) {
          toggleNav("#main-side-nav")
        }
        // main toggles
        toggleNav(target)
      })

      function toggleNav(target) {
        if ($(target).length === 0) return
        if (!$(target).hasClass('active')) {
          createOverlay(target)
          if (target === "#main-nav") {
            animeEntry(target)
          }
          $(target).addClass('active');
          $('body').addClass('nav-opened')
          $('.nav-trigger[data-target="' + target + '"]').addClass('active')
        } else {
          removeOverlay(target)
          if (target === "#main-nav") {
            animeLeave(target)
          }
          $(target).removeClass('active');
          $('body').removeClass('nav-opened')
          $('.nav-trigger[data-target="' + target + '"]').removeClass('active')

        }
      }

      function animeEntry(target) {
        let mainTarget = $(target),
          animateItems = mainTarget.find('.move-anim'),
          tl = new TimelineLite()
        $('body').addClass('no-scroll')
        if (!$('#main-wrapper').hasClass('dashboard-content-layout')) {

          $('body,#main-header,.bottom-scroll-indicator').css("padding-right", S.scrollBarWidth + 'px')
        }

        TweenLite.set(mainTarget, { autoAlpha: 0 })
        TweenLite.set(animateItems, { y: 50, autoAlpha: 0 })

        tl.to(mainTarget, 0.5, { display: 'block', autoAlpha: 1, ease: Power0.easeNone })
          .staggerTo(animateItems, 0.5, { y: 0, autoAlpha: 1 }, 0.05, 0.2)
      }

      function animeLeave(target) {
        let mainTarget = $(target),
          animateItems = mainTarget.find('.move-anim'),
          tl = new TimelineLite({
            onComplete: function() {
              $('body').removeClass('no-scroll')
              if (!$('#main-wrapper').hasClass('dashboard-content-layout')) {

                $('body,#main-header,.bottom-scroll-indicator').css("padding-right", '0px')
              }

            }
          })


        tl.to(mainTarget, 0.5, { display: 'none', autoAlpha: 0, ease: Power0.easeNone, overwrite: "all" }, 0.2)
          .staggerTo(animateItems, 0.5, { y: 50, autoAlpha: 0, overwrite: "all" }, -0.05, 0)
      }


      function createOverlay(target) {
        let overlay = "<div class='nav-overlay' data-target=" + target + "></div>";
        $(overlay).hide().appendTo('#main-wrapper').show()
      }

      function removeOverlay(target) {
        $('.nav-overlay[data-target="' + target + '"]').remove()
      }

      $(document).on("click touchstart", ".nav-overlay", function() {
        let target = $(this).data('target');
        toggleNav(target)
      })
    },


    // Anchor links
    anchorLinks() {
      let links = $('.anchor-link')

      links.click(function(e) {
        e.preventDefault()
        let target = $(this).data('target') ? $($(this).data('target')).offset().top - 200 : S.windowHeight
        $('html,body').animate({scrollTop: target })
      })
    },

    // Preset section animation
    animationPresetA(elem) {
      let tl = new TimelineMax(),
        waves = $(elem).find('#wave-a'),
        artSection = $(elem).find('.art-section'),
        graphs = $(elem).find('.graphs > path'),
        person = $(elem).find('.person'),
        plus = $(elem).find('.plus > g'),
        iconCircleHolder = $(elem).find('.svg-circle-holder-wrapper,.svg-grade-holder-wrapper')

      tl.staggerFrom($(waves).find('path'), 1, {
          //scale: 0.5,
          rotation: 40,
          autoAlpha: 0,
          ease: Back.easeOut,
          transformOrigin: '50% 100%'
        }, 0.1)
        .staggerFrom(graphs, 0.5, { drawSVG: "0% 0%", autoAlpha: 0 }, -0.2, 0.5)
        .from(person, 0.5, { y: 50, x: -20, autoAlpha: 0 }, 0.9)
        .staggerFrom(plus, 0.5, { y: 50, autoAlpha: 0 }, 0.05, 1)
        .from(iconCircleHolder, 0.5, { scale: 0.5, autoAlpha: 0, ease: Back.easeOut })

      return tl
    },

    animationPresetB(elem) {
      CSSPlugin.useSVGTransformAttr = false;
      let tl = new TimelineMax({ CSSPlugin: { useSVGTransformAttr: false } }),
        phone = $(elem).find('.phone-wrapper'),
        //phoneScreen = $(elem).find('.phone-screen>*'),
        paperPlane = $(elem).find('.paper-plane'),
        planePath = $(elem).find('#plane-mask-path'),
        planeTraces = $(elem).find('.plane-traces>path'),
        planeMotionPath = MorphSVGPlugin.pathDataToBezier(planePath, { align: paperPlane, offsetX: 0 }),
        waves = $(elem).find('.wave-icon-wrapper .short-wave>path')

      TweenLite.set(paperPlane, { scale: 0.2, yPercent: -5 })

      tl//.staggerFrom(phoneScreen, 2, { y: 100, autoAlpha: 0, ease: Power3.easeOut }, 0.1, 0.3)
        .add('plane-flight')
        .staggerFrom(waves,1,{
          rotation: 45,
          autoAlpha: 0,
          ease: Back.easeOut.config(1.8),
          transformOrigin: "0% 100%"
        },0.1,0.5)
        .from(phone, 2, { y: 100, autoAlpha: 0, ease: Power3.easeOut }, 0.8)
        .to(paperPlane, 3, {
          scale: 1,
          bezier: { values: planeMotionPath, type: "cubic", curviness: 2, autoRotate: true },
          ease: Power1.easeOut
        }, 'plane-flight')
        .to(paperPlane, 0.2, { autoAlpha: 1 }, 'plane-flight')
        .to(paperPlane, 0.5, { rotationX: 180, xPercent: 3 }, 'plane-flight+=2.2')
        //.to(paperPlane,0.5,{x: "-=30", y:"-=30", ease: Power0.easeNone},'ending-flight')
        .staggerFrom(planeTraces, 0.5, { x: 20, autoAlpha: 0 }, 0.1, 'plane-flight+=2.5')
        .from(planePath, 3, { drawSVG: "0% 0%" }, 'plane-flight+=0.05')

      return tl
    },

    // lazyLoadImg
    lazyLoadImg(elems){
      let images = elems || $('.lazy-load-img'),
          processFigure = function(figure) {
            let imageSrc = $(figure).find('>img').data('src')
            if (imageSrc) {
              var img = $("<img />").attr('src', imageSrc)
              PassportObj.getImageSize(img, function(w, h) {
                $(figure).find('>img').attr({
                  'width': w,
                  'height': h
                })
              })
              if (img.complete) {
                giveImage(imageSrc, figure);
              } else {
                img.on('load', function() {
                    giveImage(imageSrc, figure);
                  })
                  .on('error', function() {
                    
                  })
              }
            }
          },
          giveImage = function(imageSrc, figure){
            $(figure).addClass('loaded');
            $(figure).find('>img').attr('src', imageSrc)
          }
      if(images.length){
        let scrollController = new ScrollMagic.Controller()

        images.each(function(){
          let scene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: 'onEnter' })
          .on('enter', function() {
            var triggerElem = this.triggerElement();
            processFigure(triggerElem);
          })
          .addTo(scrollController)
        })
      }
      
    },

    getImageSize (img, callback) {
      var $img = $(img);

      var wait = setInterval(function() {
          var w = $img[0].naturalWidth,
              h = $img[0].naturalHeight;
          if (w && h) {
              clearInterval(wait);
              callback.apply(this, [w, h]);
          }
          }, 30);
    },

    // Parallax 
    parallaxOvers() {
      let canvas = $('.hover-parallax-wrapper')

      canvas.on('mousemove', onHover)

      function onHover(e) {
        let pageWt = window.innerWidth,
          pageHt = window.innerHeight,
          parallaxElems = $(this).find('.parallax-elem'),
          pageX = e.pageX - pageWt,
          pageY = e.pageY - pageHt,
          fixer = -0.002
        if (parallaxElems.length) {
          parallaxElems.each(function(index, value) {
            fixer = $(this).data('fixer') || fixer - 0.008
            TweenMax.to(this, 1, {
              x: (this.clientLeft + pageX) * fixer,
              y: (this.clientTop + pageY) * fixer,
              ease: Power2.easeOut,
              //overwrite: "all"
            })
          })
        }
      }
    },

    parallaxScrolls() {
      let items = $('.scroll-item-down-parallax'),
      scrollController = new ScrollMagic.Controller()

      items.each(function(){
        let duration = $(this).data('duration') ? $(this).data('duration') : "400%",
            hook = $(this).data('hook') ? $(this).data('hook') : "onEnter",
            autoAlpha = $(this).data('alpha-out') ? 0 : 1,
            triggerElement = $(this).data('trigger-parent') ? $(this).parent()[0] : this

           

        let tween = TweenLite.to($(this),0.5,{
          y:"100%", 
          rotation:0.002,
          autoAlpha: autoAlpha
        })
        let scene = new ScrollMagic.Scene({
              triggerElement: triggerElement,
              triggerHook: hook,
              reverse: true,
              duration: duration
            })
            .setTween(tween)
            .addTo(scrollController);
      })
    },

    // Main hero animation
    heroAnimation (node) {
      let tween = new TimelineMax(),
      hero = node.find('.hero'),
      logo = node.find('.main-logo'),
      tie = node.find('.tie-base'),
      planePath = node.find('#plane-mask-path-b'),
      plane = node.find('.paper-plane'),
      planeRes = node.find('.plane-res'),
      tieAnimation = new TimelineMax({
        repeat: 5,
        yoyo: true,
        ease: Power0.easeNone
      })

      tween.from(hero, 1, {autoAlpha: 0, y: 100})
      .from(planePath, 1, {
        drawSVG:"0% 0%"
      })
      .from(plane, 0.5, {x: -20, y :20, autoAlpha: 0},'planeMotion')
      .staggerFrom(planeRes.find('>path'), 0.5, {x: -20, autoAlpha: 0},0.1,'planeMotion+=0.1')
      .from(logo, 1, {autoAlpha: 0},1.1)
      .add(tieAnimation, 0)
      

      tieAnimation
      .to(tie,0.2,{
        morphSVG: {
          shape: node.find('.t-to-1'),
          
        },
        //y: -1,
        //x: 1,
        ease: Power0.easeNone
      })
      // .to(tie,0.2,{
      //   morphSVG: {
      //     shape: node.find('.t-to-2'),
          
      //   },
      //   ease: Power0.easeNone
      // })
      return tween
    },

    partnersAnimation(node) {
      let tl = new TimelineMax(),
      woman = node.find('.woman'),
      man = node.find('.man')

      tl.from(woman,1,{autoAlpha: 0, x : -100, ease: Power2.easeOut})
      .from(man,1,{autoAlpha: 0, x : 100, ease: Power2.easeOut},0)

      return tl
    },

    // Tooltips
    toolTips() {
      let toolTips = $('.info-link')

      toolTips.click(function(){
        $(this).addClass('active')
      })

      
        $(document.body).on('click touchstart', function (e){
          
          var container = $(".info-link.active");

          if (!container.is(e.target) && container.has(e.target).length === 0){

            $(".info-link.active").removeClass('active')
            
          }
        }); 
      
      
    },

    //emailPopup
    emailPopup(){
      let emailPopupOpenTrigger = $('.open-emailer-trigger'),
          emailPopupCloseTrigger = $('.emailer-popup .close-popup')

      emailPopupOpenTrigger.on('click', function(e) {
        e.preventDefault()
        let emailer = $(this).next()
        TweenLite.to(emailer,0.5,{
          display: "block",
          autoAlpha: 1,
          top: "50%",
          ease: Power2.easeOut,
          onStart() {
            $('body').addClass('emailer-on')
          }
        })
      })

      emailPopupCloseTrigger.on('click', function(e) {
        e.preventDefault()
        let emailer = $(this).parent()
        TweenLite.to(emailer,0.5,{
          display: "none",
          autoAlpha: 0,
          top: "70%",
          ease: Power2.easeOut,
          onStart() {
            $('body').removeClass('emailer-on')
          }
        })
      })
    },

    // Fixed bars
    fixedSideBars() {
      let sideBar = $('.aside-fixed-bar'),
      scrollController = new ScrollMagic.Controller()

      new ScrollMagic.Scene({
        triggerElement: sideBar[0],
        triggerHook: 'onLeave',
        offset: -100,
        duration: $('.inner-content-wrapper').innerHeight() - 150
      })
      .setPin(sideBar[0])
      .setClassToggle(sideBar[0],'fixed')
      .addTo(scrollController)
    },

    // detect animation on scroll
    detectAnimation(elems) {
      var controller = new ScrollMagic.Controller();
      var elem = elems || $('.detect-animate');

      elem.each(function() {
       
        var elem = this;
        var triggerElem = $(elem).data('top') ? $(elem).data('top') : elem;
        var elementAnimation = $(elem).data('animation');
        var delay = $(elem).data('delay') ? $(elem).data('delay') : 0;
        var speed = $(elem).data('speed') ? $(elem).data('speed') : 1;
        var hook = $(elem).data('hook') ? $(elem).data('hook') : 'onEnter';
        var offset = $(elem).data('offset') || 200;
        var tween = '';
        var duration = 0;
        var reverse = ($(elem).data('reverse') === false) ? false : true;
        var staggerOffset = 0.1;
        var ease = $(elem).data('ease') ? $(elem).data('ease') : Power2.easeOut;
        
        TweenLite.set(elem, { autoAlpha: 1 });

        if(window.innerWidth <= 1024) offset = 5;

        switch (elementAnimation) {
          case "fade-in":
            tween = TweenMax.from(elem, speed, { autoAlpha: 0, ease: ease, delay: delay });
            break;
          case "from-top":
            tween = TweenMax.from(elem, speed, { y: '-100px', opacity: 0, ease: ease, delay: delay });
            break;
          case "from-top-jerk":
            tween = TweenMax.from(elem, speedspeed, { y: '-100px', opacity: 0, ease: Back.easeInOut, delay: delay });
            break;
          case "from-bottom":
            tween = TweenMax.from(elem, speed, { y: '100px', opacity: 0, ease: ease, delay: delay });
            break;
          case "from-bottom-jerk":
            tween = TweenMax.from(elem, speed, { y: '100px', opacity: 0, ease: Back.easeInOut, delay: delay });
            break;
          case "from-left":
            tween = TweenMax.from(elem, speed, { x: '-100px', opacity: 0, ease: ease, delay: delay });
            break;
          case "from-left-jerk":
            tween = TweenMax.from(elem, speed, { x: '-100px', opacity: 0, ease: Back.easeInOut, delay: delay });
            break;
          case "from-right":
            tween = TweenMax.from(elem, speed, { x: '100px', opacity: 0, ease: ease, delay: delay });
            break;
          case "from-right-jerk":
            tween = TweenMax.from(elem, speed, { x: '100px', opacity: 0, ease: Back.easeInOut, delay: delay });
            break;
          case "from-bottom-elements-lazy":
            tween = TweenMax.staggerFrom($(elem).find('>*'), speed, { y: '100px', opacity: 0, ease: ease, delay: delay }, staggerOffset);
            break;
          case "from-bottom-elements-lazy-jerk":
            tween = TweenMax.staggerFrom($(elem).find('>*'), speed, { y: '100px', opacity: 0, ease: Back.easeInOut, delay: delay }, staggerOffset);
            break;
          case "from-left-elements-lazy":
            tween = TweenMax.staggerFrom($(elem).find('>*'), speed, { x: '-100px', opacity: 0, ease: ease, delay: delay }, staggerOffset);
            break;
          case "from-left-elements-lazy-jerk":
            tween = TweenMax.staggerFrom($(elem).find('>*'), speed, { x: '-100px', opacity: 0, ease: Back.easeInOut, delay: delay }, staggerOffset);
            break;
          case "from-right-elements-lazy":
            tween = TweenMax.staggerFrom($(elem).find('>*'), speed, { x: '100px', opacity: 0, ease: ease, delay: delay }, staggerOffset);
            break;
          case "from-right-elements-lazy-jerk":
            tween = TweenMax.staggerFrom($(elem).find('>*'), speed, { x: '100px', opacity: 0, ease: Back.easeInOut, delay: delay }, staggerOffset);
            break;
          case "from-top-elements-lazy":
            tween = TweenMax.staggerFrom($(elem).find('>*'), speed, { y: '-100px', opacity: 0, ease: ease, delay: delay }, staggerOffset);
            break;
          case "from-bottom-items-lazy":
            tween = TweenMax.staggerFrom($(elem).find('.animate-item'), speed, { y: '100px', opacity: 0, ease: ease, delay: delay }, staggerOffset);
            break;
          case "from-bottom-items-lazy-jerk":
            tween = TweenMax.staggerFrom($(elem).find('.animate-item'), speed, { y: '100px', opacity: 0, ease: Back.easeInOut, delay: delay }, staggerOffset);
            break;
          case "from-left-items-lazy":
            tween = TweenMax.staggerFrom($(elem).find('.animate-item'), speed, { x: '-100px', opacity: 0, ease: ease, delay: delay }, staggerOffset);
            break;
          case "from-left-items-lazy-jerk":
            tween = TweenMax.staggerFrom($(elem).find('.animate-item'), speed, { x: '-100px', opacity: 0, ease: Back.easeInOut, delay: delay }, staggerOffset);
            break;
          case "draw-bank":
            tween = new TimelineLite()
            .staggerFrom($(elem).find('.house>g>path'), speed, {
              y: 50,
              autoAlpha: 0
            },0.2)
            .staggerFrom($(elem).find('.house>g>g>path'), speed, {
              autoAlpha: 0
            },0.2)
            break;
          case "animation-preset-a":
            tween = new TimelineMax()
            tween.from(elem, 0.5, { autoAlpha: 0 })
              .add(PassportObj.animationPresetA(elem))
            break;
          case "animation-preset-b":
            tween = new TimelineMax()
            tween.from(elem, 0.5, { autoAlpha: 0 })
              .add(PassportObj.animationPresetB(elem))
            break;
          case "wave-pattern-b":
            tween = new TimelineMax()
            tween.staggerFrom($(elem).find('path'), 1, { autoAlpha: 0, ease: Back.easeOut }, 0.1)
            break;
          case "info-animation-a":
            tween = new TimelineMax()
            tween.from(elem, 1, { autoAlpha: 0 })
              .from($(elem).find('.icon-holder'), 1, { autoAlpha: 0, scale: 0.5 }, 0.2)
              .staggerFrom($(elem).find('.icon-holder path'), 1, { drawSVG: "20% 20%" }, 0.1, 0.5)
              .staggerFrom($(elem).find('ul>li'), 1, { y: 40, autoAlpha: 0 }, 0.1, 0.5)
            break;
          case "info-animation-b":
            tween = new TimelineMax()
            tween.from(elem, 1, { autoAlpha: 0 })
              .from($(elem).find('.icon-holder'), 1, { autoAlpha: 0, scale: 0.5 }, 0.2)
              .staggerFrom($(elem).find('.icon-holder path'), 1, { drawSVG: "20% 20%" }, 0.1, 0.5)
              .staggerFrom($(elem).find('.content-holder>*'), 1, { y: 40, autoAlpha: 0 }, 0.1, 0.5)
            break;
          case "grade-selector":
            let textTween = new TimelineMax(),
              textGradeGroup = $(elem).find('.text-grade-group > g'),
              textIterator = 0
            tween = new TimelineMax()
            tween.from(elem, 1.3, { autoAlpha: 0, y: 120 })
              .staggerFrom($(elem).find('.grade-triggers text'), 0.8, { autoAlpha: 0 }, 0.1, 0.2)
              .add(textTween, 0)
              .to($(elem).find('.grade-icon-wrapper'), 0.5, { width: 466 }, 2)
              .staggerFrom($(elem).find('.info-text> *'), 0.5, { y: 50, autoAlpha: 0 }, 0.1)

            textGradeGroup.each(function(key, value) {
              TweenLite.set(this, { y: -15, transformOrigin: 'center' })
              textTween.to(this, 0.2, { autoAlpha: 1, y: 0, transformOrigin: 'center', ease: Power0.easeNone }, 0 + textIterator)
              if (!$(this).hasClass('active')) {
                textTween.to(this, 0.2, { autoAlpha: 0, y: 15, transformOrigin: 'center', ease: Power0.easeNone })
              }
              textIterator += 0.3
            })
            break;
          case "the-hero":
            tween = new TimelineMax()
            tween.add(PassportObj.heroAnimation($(elem)))
            break;
          case "the-partners":
            tween = new TimelineMax()
            tween.add(PassportObj.partnersAnimation($(elem)))
            break;
          case "the-phone":
            tween = new TimelineMax()
            tween.from($(elem).find('.telephone-svg .main-outline>path:first-child'),2,{drawSVG:"0% 0%", autoAlpha:0})
            .from($(elem).find('.telephone-svg .main-outline>.end'),1,{drawSVG:"0% 0%", autoAlpha:0},0)
            .from($(elem).find('.telephone-svg .dial'),1.2,{rotation:-465, ease: Back.easeOut, transformOrigin:"center center", autoAlpha:0},0.2)
            break;
          case "icon-article-reveal":
            tween = new TimelineMax()
            tween.from($(elem).find('.icon-wrapper'),0.5,{
              autoAlpha: 0,
              scale: 0,
              ease: Back.easeOut
            })
            .from($(elem).find('.icon-wrapper .main-icon'),0.5,{
              autoAlpha: 0,
              y: 50,
              ease: Back.easeOut
            },0.3)
            .staggerFrom($(elem).find('.content > *'),0.5,{
              autoAlpha: 0,
              y: 50
            },0.1,0.5)
            break;
          case "wave-rotate":
          tween = new TimelineMax()
          tween.staggerFrom($(elem).find('.short-wave > path'),1,{
            rotation: 45,
            autoAlpha: 0,
            ease: Back.easeOut.config(1.8),
            transformOrigin: '0% 100%'
          },0.1)
          .from($(elem).find('.icon-center'),0.8,{autoAlpha: 0, scale: 0.5},0.5)
          break;
          case "wave-rotate-b":
            tween = new TimelineMax()
            tween.staggerFrom($(elem).find('.short-wave > path'),1,{
              rotation: 45,
              autoAlpha: 0,
              ease: Back.easeOut.config(1.8),
              transformOrigin: '0% 100%'
            },0.1)
            .from($(elem).find('.icon-center'),0.5,{autoAlpha: 0, scale: 0.5},0.2)
          break;
          case "plus-group-animation":
            tween = new TimelineMax()
            tween.staggerFromTo($(elem).find('.plus-wrapper>*, .icon-plus-group path'), 0.5, {
              y: 50,
              autoAlpha: 0,
            }, {
              y: 0,
              autoAlpha: 1,
            }, 0.1, 0.4)
            .staggerFromTo($(elem).find('.cog'), 1, {
              rotation: 0
            }, {
              rotation: 180,
              transformOrigin: "50% 50%",
              ease: Back.easeOut.config(1.7)
            }, 0.1, 0.2)
          break;
          default:
            tween = '';
        };

        new ScrollMagic.Scene({ triggerElement: triggerElem, triggerHook: hook, offset: offset, duration: duration, reverse: false })
          .setTween(tween)
          .addTo(controller);


      })
    }
  }

  PassportObj._init()
  window.CreditPassport = PassportObj

})(jQuery)
