(function($){
	let defaultOptions={
		hook: "onEnter",
		hookOffset: 0,
		reverse: false,
		lineDuration: "200%",
		contentStaggerDuration: 0.8
	}

	const MainTimeline = function(ele,options){
		this.settings = $.extend({},defaultOptions, options)
		this.$container = $(ele),
		this.$line = this.$container.find('.timeline-line')
		this.$timelineItems = this.$container.find('.timeline-item')
		this.$navigationSectionBtn = this.$container.find('.navigate-section')
		this.$scrollWrapper = this.$container.find('.timeline-scrollwrapper')
		this.scrollDirectionYTemp = 0
		this.scrollController = new ScrollMagic.Controller({container: this.$scrollWrapper[0]})
		this.currentLineOffset = 0
	}

	MainTimeline.prototype = {
		init() {
			TweenLite.to(this.$container,0.2,{autoAlpha: 1})
			for(let i=0; i<=this.$timelineItems.length - 1; i++){
				this.setScene(this.$timelineItems[i], i)

			}

			//this.navigateSectionScroll()
			setTimeout(() => {
			 this.scrollBars()	
			},1000)
			
			//this.lineScene()
		},

		setScene(item, index){
			let articleContents = $(item).find('.timeline-article > *'),
			dot = $(item).find('.timeline-dot'),
			tween = new TimelineMax(),
			canvasHeight =  this.$container.find('.timeline-list').outerHeight(),
			lineOffseting = 100/this.$timelineItems.length * (index + 1) < 100 ? 100/this.$timelineItems.length * (index + 1) : 100

			
			
			tween.staggerFrom(articleContents,this.settings.contentStaggerDuration,{
				y: 100,
				autoAlpha: 0,
				ease: Power1.easeInOut
			}, 0.1,0.1)
			.to(dot,0.8, {
				autoAlpha: 1,
				scale: 1,
				ease: Back.easeOut.config(3)
			},0)

			let scene = new ScrollMagic.Scene({
				triggerElement: item,
				triggerHook: this.settings.hook,
				offset: this.settings.hookOffset,
				reverse: this.settings.reverse,
				duration: 0
			})
			.setTween(tween)
			.addTo(this.scrollController);

			let classToggleScene = new ScrollMagic.Scene({
				triggerElement: item,
				triggerHook: this.settings.hook,
				reverse: true,
				duration: $(item).outerHeight()
			})
			.on('enter', (e) => {

				if(!this.settings.reverse){
					if(e.scrollDirection === "FORWARD"){
						if(this.currentLineOffset < lineOffseting) {
							this.currentLineOffset = lineOffseting
						}
						TweenLite.to(this.$line, 1, {height: this.currentLineOffset  + "%"})
					}
					return
				}
				if(e.scrollDirection === "REVERSE" && $(e.currentTarget.triggerElement()).index() === 1){
					TweenLite.to(this.$line, 1, {height: "0%",overwrite: "all"})
				} else {
					TweenLite.to(this.$line, 1, {height: lineOffseting  + "%"})
				}
				this.currentLineOffset = lineOffseting
				
			})
			.setClassToggle(item,"active")
			.addTo(this.scrollController);


		},

		lineScene() {
			let tween = TweenLite.to(this.$line,1,{height: "100%"})

			let scene = new ScrollMagic.Scene({
				triggerElement: this.$timelineItems[0],
				triggerHook: this.settings.hook,
				offset: this.settings.hookOffset,
				reverse: this.settings.reverse,
				duration: this.$container.innerHeight() + 200
			})
			.setTween(tween)
			.addTo(this.scrollController);
		},

		navigateSectionScroll() {
			let scene = new ScrollMagic.Scene({
				triggerElement: this.$container[0],
				triggerHook: "onLeave",
				duration: this.$container.innerHeight() - 1000,
			})
			//.setPin(this.$navigationSectionBtn[0])
			.setClassToggle(this.$navigationSectionBtn[0],'fixed')
			.addTo(this.scrollController);

			this.$navigationSectionBtn.on('click', () => {
				$('html, body').animate({scrollTop: this.$container.offset().top + this.$container.innerHeight() - 200},'slow')
			})

		},

		scrollBars() {
			let scrollWrapper = this.$scrollWrapper
			scrollWrapper.addClass('reached-top')
			this.$scrollWrapper.niceScroll({
	        cursorcolor: "transparent",
	        cursorwidth: "12px",
	        cursorborder: "0px solid #000",
	        scrollspeed: 100,
	        autohidemode: false,
	        hidecursordelay: 1000,
	        background: 'transparent',
	        cursorfixedheight: false,
	        cursorminheight: 20,
	        enablekeyboard: true,
	        horizrailenabled: true,
	        bouncescroll: true,
	        smoothscroll: true,
	        iframeautoresize: true,
	        touchbehavior: true,
	        zindex: 'auto'
	      })

		  scrollWrapper.bind("scroll", () => {
	      	this.scrollUpdates(scrollWrapper.getNiceScroll()[0].getScrollTop())
	      })
		},

		scrollUpdates(scrollTop) {
			let scrollWrapper = this.$scrollWrapper
			if(this.scrollDirectionYTemp < scrollTop){
			      	scrollWrapper.addClass('scrolling-down')
			      	scrollWrapper.removeClass('scrolling-up')
			      } else {
			      	scrollWrapper.removeClass('scrolling-down')
			      	scrollWrapper.addClass('scrolling-up')
			      }

			      
			      this.scrollDirectionYTemp = scrollTop


			      if(scrollTop >= scrollWrapper.getNiceScroll()[0].getContentSize().h - scrollWrapper.innerHeight() - 100) {
			      	scrollWrapper.addClass('reached-bottom')
			      } else {
			      	scrollWrapper.removeClass('reached-bottom')
			      }

			      if(scrollTop >= 200){
			      	scrollWrapper.removeClass('reached-top')
			      } else {
			      	scrollWrapper.addClass('reached-top')
			  }
		}
	}

	

	$.fn.TimelineInfo = function(options) {
		return new MainTimeline(this, options)
	}
    
    if($('.timeline-canvas').length){
    	$('.timeline-canvas').TimelineInfo({
			reverse: true
		}).init()
    }
	
})(jQuery)